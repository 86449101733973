<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование листа"
        update
        url-update="/keyboard-list/update/:id"
        url-back="/keyboard-list"
        action-one="keyboard_list/one"
        :fields="fields"
    >
        <keyboard-list-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import KeyboardListForm from '@/components/forms/KeyboardListForm.vue'
export default {
    name: 'KeyboardListUpdate',
    components: { FormView, KeyboardListForm },
    computed: {
        fields () {
            return ['title', 'value']
        }
    }
}
</script>